import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { injectIntl } from "react-intl";
import Router from "next/router";
import { createMarketCart, getMarketCart, getProducts } from "../../api";
import Button from "../Button";
import styles from "./Tickets.styles.css";
import { DayPickerSingleDateController, DayPickerRangeController } from "react-dates";
import { START_DATE, END_DATE } from 'react-dates/constants';
import moment from "moment";
import ReactTooltip from "react-tooltip";
import ModalWindow from "../ModalWindow/ModalWindow";

const Group = ({ group, tickets, selectedOption, startDate, endDate, type, onValueChange }) => {
  const [collapsed, setCollapsed] = useState(true);
  let titleClass = [styles.row, styles.title];
  if (collapsed) {
    titleClass = [...titleClass, styles.collapsed];
  }
  const id = group.name;
  console.log('group', group);

  const formats = group.formats.map((group, index) => {
    let stock = group.stock;

    let amount = group.amount;
    let day = startDate.format('DD/MM/YYYY')
    if(startDate != endDate){
      day = startDate.format('DD/MM/YYYY') + "-" + endDate.format('DD/MM/YYYY')
    }
    let products = []
    products.push({
      id: group.id,
      day: startDate.format('DD/MM/YYYY')
    });
    if(type === 'HOTEL'){

      let daysLeft = false;
      let groupProducts = []
      let groupAmount = 0;
      let stock = Number.MAX_VALUE;
      let lastDay = undefined;
      group.schedules.forEach(s => {
        groupAmount += s.amount;
        groupProducts.push({
          id: s.id,
          day: s.day
        });
        if(s.realStock < stock){
          stock = s.realStock;
        }
        if(lastDay === undefined){
          lastDay = s.day;
        }else{
          const nextLastDay = moment(lastDay, 'DD/MM/YYYY').add(1, 'days');
          if(nextLastDay.format('DD/MM/YYYY') !== s.day){
            daysLeft = true;
          }else{
            lastDay = s.day;
          }
        }
      });

      if(groupAmount !== 0){
        amount = groupAmount;
      }

      if(groupProducts.length > 0){
        products = groupProducts;
      }

      if(group.schedules.length == 0 || daysLeft){
        return undefined;
      }

    }else if(type !== 'PRODUCT'){
      const scheduleIndex = group.schedules.findIndex(s => {
        let value = s.start;
        if(s.start !== s.end){
          value = `${s.start} - ${s.end}`;
        }
        return value === selectedOption
      });

      if((group.schedules > 0 && scheduleIndex === -1) || group.schedules[scheduleIndex] === undefined){
        return undefined;
      }

      if(group.schedules.length > 0){
        stock = group.schedules[scheduleIndex].realStock;
      }
    }

    const ticketIndex = tickets.findIndex(
      (t) => t.id === id && t.name === group.value && t.day === day && t.hour === selectedOption);
    const ticket = tickets[ticketIndex];
    console.log('tickets', tickets);
      return (
        <div key={index} className={classnames(styles.row, styles.ticketBody)}>
          <div className={styles.ticketLabel}>
            <span
              data-for="main"
              data-tip={group.message}
              data-iscapture="true">
                {group.value}
                <ReactTooltip
                  id="main"
                  place={'left'}
                  type={'dark'}
                  effect={'float'}
                  multiline={true}
                />
            </span>
            <span style={{ float: "right", marginRight: "20px" }}>
              {amount}€
            </span>
          </div>
          <div>
            <button
              onClick={() => {
                const item = {
                  id: id,
                  name: group.value,
                  value: ticket ? ticket.value - 1 : 0,
                  day: day,
                  hour: selectedOption,
                  products: products
                };
                if (item.value < 0) return;
                onValueChange(item);
              }}
              className={styles.ticketButton}
            >
              -
            </button>
            <span className={styles.ticketAmount}>
              {ticket ? ticket.value : "0"}
            </span>
            <button
              onClick={() => {
                if(ticket){
                  stock -= ticket.value;
                }
                if(stock > 0){
                  const item = {
                    id: id,
                    name: group.value,
                    value: ticket ? ticket.value + 1 : 1,
                    day: day,
                    hour: selectedOption,
                    products: products
                  };
                  onValueChange(item);
                }
              }}
              className={styles.ticketButton}
            >
              +
            </button>
          </div>
        </div>
      );
    });
  if(formats.length === 0 
    || (formats.length === 1 && formats[0] === undefined)
    || formats == ','){
    return ('');
  }
  return (
    <div>
      <span
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        className={classnames(titleClass)}
      >
        {group.name}
      </span>
      {collapsed &&
        formats
      }
    </div>
  );
};

Group.defaultProps = {
  group: {
    name: "",
    formats: [],
  },
  tickets: [],
  onValueChange: () => {},
};

const Tickets = ({ groups, defaultStartDate, defaultEndDate, intl, destination, schedules, categoryId, onChangeDate, onChangeRangeDate, id, addClassContainer }) => {

  let type = undefined;
  if(groups.length > 0){
    type = groups[0].type;
  }

  if(type === undefined && categoryId === 'alojamiento'){
    type = "HOTEL";
  }

  const [tickets, setTickets] = useState([]);
  const [focused, setFocused] = useState('startDate');
  const [startDate, setStartDate] = useState(moment(defaultStartDate));
  const [endDate, setEndDate] = useState(moment(defaultEndDate));
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  //const [type, setTyte] = useState('event');

  const desktopDatesRef = useRef();
  const datesStartModalRef = useRef();
  const datesEndModalRef = useRef();

  const modalsRefs = [
    datesStartModalRef,
    datesEndModalRef
  ];

  const getElementBounds = (ref) => ref.getBoundingClientRect();

  if(startDate !== defaultStartDate){
    setStartDate(defaultStartDate);
  }

  if(endDate !== defaultEndDate){
    setEndDate(defaultEndDate);
  }

  const hideAll = () => {
    modalsRefs.forEach((d) => {
      if (d.current && d.current.hide) {
        d.current.hide();
      }
    });
  };

  const onValueChange = useCallback(
    (item) => {
      const itemIndex = tickets.findIndex((t) => t.id === item.id && t.name === item.name && t.day === item.day && t.hour === item.hour);
      if (itemIndex !== -1) {
        setTickets(
          tickets.map((t, index) => {
            if (index === itemIndex) return item;
            return t;
          })
        );
        return;
      }
      setTickets(tickets.concat([item]));
    },
    [tickets]
  );

  moment.locale("es");

  useEffect(() => {
    if (startDate) {
      if(type === 'HOUR'){
        setOptionsHourType();
      }else if(type === 'HOTEL'){
        setOptionsHotelType();
      }else if(type === 'PRODUCT'){
        setOptionsProductType();
      }else if(type === 'LIST'){
        setOptionsListType();
      }else{
        setOptionsOtherType(startDate);
      }
    }
  }, [startDate]);

  const setOptionsHourType = () => {
    let scheduleOptions = [];
    groups.forEach( g => {
      g.formats.forEach(f => {
        f.schedules.forEach(s => {
          let value = s.start;
          if(s.start !== s.end){
            value = `${s.start} - ${s.end}`;
          }
          if(scheduleOptions.indexOf(value) < 0) {
            scheduleOptions.push(value);
          }
        })
      });
    });

    setOptions(scheduleOptions);
    if (scheduleOptions.length > 1) {
      setSelectedOption(scheduleOptions[0]);
      return;
    }
    setSelectedOption(scheduleOptions[0]);
  }

  const setOptionsHotelType = () => {
    setOptions(['']);
    setSelectedOption('');
  }

  const setOptionsProductType = () => {
    setOptions(['']);
    setSelectedOption('');
  }

  const setOptionsListType = () => {
    let scheduleOptions = [];
    groups.forEach( g => {
      g.formats.forEach(f => {
        f.schedules.forEach(s => {
          let value = s.start;
          if(s.start !== s.end){
            value = `${s.start} - ${s.end}`;
          }
          if(scheduleOptions.indexOf(value) < 0) {
            scheduleOptions.push(value);
          }
        })
      });
    });
    console.log('scheduleOptions', scheduleOptions);
    setOptions(scheduleOptions);
    if (scheduleOptions.length > 1) {
      setSelectedOption(null);
      return;
    }
    setSelectedOption(scheduleOptions[0]);
  }

  const setOptionsOtherType = (date) => {
    const formattedDate = date.format("e");
    const dayOfWeek = date.day()
    const dateIndex = schedules.findIndex((s) => ( s.day === formattedDate || s.day == dayOfWeek ) && s.closed === false);
    console.log(schedules[dateIndex]);
    if (dateIndex != -1 && !schedules[dateIndex].closed) {
      console.log(schedules[dateIndex].schedule.trim().toLowerCase());
      const scheduleOptions = schedules[dateIndex].schedule
        .replace("de ", "")
        .split(" y ");
      setOptions(scheduleOptions);
      if (scheduleOptions.length > 1) {
        setSelectedOption(null);
        return;
      }
      setSelectedOption(scheduleOptions[0]);
    }
    if (dateIndex == -1 || (dateIndex != -1 && schedules[dateIndex].closed)) {
      setOptions([]);
      setSelectedOption(null);
    }
  }

  const pushToCheckout = () => {
    if (!startDate) return;
    if (!endDate) return;
    if (selectedOption===null) return;
    if (count === 0) return;
    const mappedTickets = [];
    tickets
      .filter((t) => t.value !== 0)
      .map((t) => {
        const time = t.hour.split(" a ")[0];
        t.products.map(product => {
          mappedTickets.push(
            {
              productId: product.id,
              quantity: t.value,
              information: `${product.day} ${time}`,
            }
          );
        })
      });
    console.log('mappedTickets', mappedTickets);
    setIsSending(true);
    createMarketCart({
      products: mappedTickets,
    })
      .then((data) => {
        const { id } = data;
        const totem = localStorage.getItem('totem');
        if(totem){
          Router.push(`/${intl.locale}/${destination}/checkout-totem?uuid=${id}`);
        }else{
          Router.push(`/${intl.locale}/${destination}/checkout?uuid=${id}`);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSending(false);
      });
  };
  const count = tickets.reduce((acc, item) => acc + item.value, 0);
  //console.log('groups', groups)

  const singleDate = (type === undefined || type === 'EVENT' || type === 'HOUR' || type === 'LIST');
  const rangeDate = type === 'HOTEL';

  if(firstLoad){
    setFirstLoad(false);
    if(singleDate){
      onChangeDate(startDate);
    }
  }
  console.log('options', options);
  return (
    <div className={classnames(styles.ticketsContainer, addClassContainer)}>
      <div className={styles.dateRow}>
        {
          singleDate &&
          <DayPickerSingleDateController
            noBorder={true}
            hideKeyboardShortcutsPanel={true}
            id="ticketDdate"
            readOnly={true}
            focused={focused}
            minDate={moment().add(-1, 'days')}
            date={startDate}
            numberOfMonths={1}
            onDateChange={(date) => {
              onChangeDate(date);
            }}
            onFocusChange={({ focused }) => {
              setFocused(focused);
            }}
            isOutsideRange={day =>
              day.isBefore(moment().add(-1, 'days'))
            }
          />
        }
        {
          rangeDate &&
          <div
              ref={desktopDatesRef}
              className={classnames(styles.optionm, styles.dates)}
            >
              <button
                onClick={() => {
                  if (!datesStartModalRef.current.isVisible()) {
                    hideAll();
                    datesStartModalRef.current.show({
                      position: getElementBounds(desktopDatesRef.current),
                    });
                    return;
                  }
                  datesStartModalRef.current.hide();
                }}
                className={classnames(styles.option, styles.start)}
              >
                <span className={styles.optionLabel}>
                  {intl.formatMessage({
                    id: "home.filter.dates.short-start",
                  })}
                </span>
                <span className={styles.optionValue}>
                  {startDate !== null
                    ? intl.formatDate(startDate.toDate(), {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })
                    : intl.formatMessage({
                        id: "commons.add-dates",
                      })}
                </span>
              </button>
              <button
                onClick={() => {
                  hideAll();
                  if (!datesEndModalRef.current.isVisible()) {
                    datesEndModalRef.current.show({
                      position: getElementBounds(desktopDatesRef.current),
                    });
                    return;
                  }
                  datesEndModalRef.current.hide();
                }}
                className={classnames(styles.option, styles.end)}
              >
                <span className={styles.optionLabel}>
                  {intl.formatMessage({
                    id: "home.filter.dates.short-end",
                  })}
                </span>
                <span className={styles.optionValue}>
                  {endDate !== null
                    ? intl.formatDate(endDate.toDate(), {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })
                    : intl.formatMessage({
                        id: "commons.add-dates",
                      })}
                </span>
              </button>
            </div>
        }
      </div>
      <div className={styles.contentSelectedDayData}>
        {options.length > 0 && (
          <div style={{ marginTop: 16 }}>
            <span>
              {intl.formatMessage({ id: "details.select-visit-hours" })}
            </span>
            <div className={styles.options}>
              {options.sort().map((option) => {
                const itemStyle =
                  selectedOption === option
                    ? [styles.option, styles.selectedOption]
                    : [styles.option];
                return (
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setSelectedOption(option);
                    }}
                    className={classnames(itemStyle)}
                    key={option}
                  >
                    {
                      option === '' &&
                      <span>{intl.formatMessage({ id: "details.not.hours" })}</span>
                    }
                    {
                      option !== '' &&
                      <span>{option}</span>
                    }
                  </a>
                );
              })}
            </div>
          </div>
        )}
        {(startDate && endDate && (selectedOption != null)) && (
          <>
            <div id={styles.container}>
              {groups.map((group, index) => (
                <Group {...{ onValueChange, group, tickets, selectedOption, startDate, endDate, type }} key={index} />
              ))}
            </div>
            {
              type &&
              <Button
                processing={isSending}
                disabled={count === 0}
                onClick={pushToCheckout}
                className={styles.cta}
                label={intl.formatMessage(
                  { id: `details.${type}.buy_now_items` },
                  { count }
                )}
              />
            }
          </>
        )}
      </div>

      <ModalWindow
        title={intl.formatMessage({
          id: "home.filter.dates.dates",
        })}
        ref={datesStartModalRef}
        classnameContent={styles.modalDatePicker}
        classnameContainer={styles.modalDatePickerContainer}
      >
        <DayPickerSingleDateController
            noBorder={true}
            hideKeyboardShortcutsPanel={true}
            id="ticketDdate"
            readOnly={true}
            focused={focused}
            minDate={moment().add(-1, 'days')}
            date={startDate}
            numberOfMonths={1}
            onDateChange={(date) => {
              onChangeRangeDate(date, null);
              hideAll()
            }}
            onFocusChange={({ focused }) => {
              setFocused(focused);
            }}
          />
      </ModalWindow>
      <ModalWindow
        title={intl.formatMessage({
          id: "home.filter.dates.dates",
        })}
        ref={datesEndModalRef}
        classnameContent={styles.modalDatePicker}
        classnameContainer={styles.modalDatePickerContainer}
      >
        <DayPickerSingleDateController
            noBorder={true}
            hideKeyboardShortcutsPanel={true}
            id="ticketDdate"
            readOnly={true}
            focused={focused}
            minDate={moment().add(-1, 'days')}
            date={endDate}
            numberOfMonths={1}
            onDateChange={(date) => {
              onChangeRangeDate(null, date);
              hideAll()
            }}
            onFocusChange={({ focused }) => {
              setFocused(focused);
            }}
          />
      </ModalWindow>
    </div>
  );
};

Tickets.defaultProps = {
  destination: "ronda",
  groups: [],
  defaultStartDate: moment(),
  defaultEndDate: moment(),
  onChangeDate: undefined
};

export default injectIntl(Tickets);
